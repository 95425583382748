import * as React from "react"
import { graphql } from "gatsby"
import { normalizeImageProps } from "../utils/image"
import Hero from "../../components/Hero/Hero"

const PrismicResourceDataBodyHero = ({ primary }) => {
  const { button_url, button_text, image, heading, subtitle } = primary

  return (
    <Hero
      image={normalizeImageProps(image)}
      heading={heading.html}
      subtitle={subtitle}
      buttonUrl={button_url?.url}
      buttonText={button_text}
    />
  )
}

export const query = graphql`fragment ResourceHero on PrismicResourceDataBodyHero {
    slice_type
    slice_label
    __typename
    primary {
        button_text
        button_url {
            url
        }
        subtitle
        heading {
            html
            text
        }
        image {
            alt
            gatsbyImageData(layout: FULL_WIDTH)
        }
    }
}
`

export default PrismicResourceDataBodyHero
