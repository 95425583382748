import * as React from "react"
import NetlifyForm from "./NetlifyForm"

export default () => (
  <NetlifyForm
    title={"Contact Us"}
    action={"/thanks"}
    name={"contact"}
    render={handleChange => (
      <React.Fragment>
        <div>
          <label>
            <div className={"label required"}>First Name</div>
            <input type="text" name="first-name" onChange={handleChange} placeholder={"First Name"} required/>
          </label>
        </div>
        <div>
          <label>
            <div className={"label required"}>Last Name</div>
            <input type="text" name="last-name" onChange={handleChange} placeholder={"Last Name"} required/>
          </label>
        </div>
        <div>
          <label>
            <div className={"label required"}>Phone</div>
            <input type="phone" name="phone" onChange={handleChange} placeholder={"Phone Number"} required/>
          </label>
        </div>
        <div>
          <label>
            <div className={"label required"}>Email</div>
            <input type="email" name="email" onChange={handleChange} placeholder={"Email"} required/>
          </label>
        </div>
        <div>
          <label>
            <div className={"label required"}>How can we help you today?</div>
            <textarea rows={10} name="message" onChange={handleChange} placeholder={"How can we help you today?"} required/>
          </label>
        </div>
      </React.Fragment>
    )}
  />

)
