import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Footer from "../../components/Footer/Footer"

const PrismicFooter = (props) => {
  return (
    <StaticQuery
      query={`${query}`}
      render={({ prismicFooter }) => {
        const { data = {} } = prismicFooter
        const {
          email = "",
          phone = "",
          cta_heading = "",
          cta_text = "",
          cta_button_text = "",
          cta_button_url = "",
          links = []
        } = data

        return (
          <React.Fragment>
            <Footer
              email={email}
              phone={phone}
              links={links}
              ctaHeading={cta_heading}
              ctaText={cta_text}
              ctaButtonText={cta_button_text}
              ctaButtonUrl={cta_button_url}
            />
          </React.Fragment>
        )
      }}
    />

  )
}

export const query = graphql`
    query footerQuery {
        prismicFooter {
            data {
                cta_button_text
                cta_heading
                cta_text
                cta_button_url {
                    url
                }
                email
                phone
                links {
                    label
                    link {
                        url
                    }
                }
            }
        }
    }
`

export default PrismicFooter;
