import { graphql, StaticQuery } from "gatsby"
import * as React from "react"
import { ReactNode } from "react"
import Button from "../../components/Button"
import Navigation from "../../components/Navigation/Navigation"

export function PrismicNavigation({ lang = "" }) {
  function createLink(linkData: any, isActive: boolean = false): ReactNode {
    const { label, link, style } = linkData
    const isLink = style !== 'Button';

    return (
      <Button
        href={link.url}
        isLink={isLink}
        primary={!isLink}
        className={[!isLink ? 'btn' : '', isActive ? 'active' : '']}
      >
        {label}
      </Button>
    )
  }

  return (
    <StaticQuery
      query={query}
      render={({ prismicMainMenu }) => {
        const { data } = prismicMainMenu

        const links = data.body.reduce((prev, menuItem: any, index: number) => {
          const { primary, items } = menuItem

          let isActive = false;
          if(typeof window !== 'undefined'){
            const {link: {url}} = primary;
            const currentPath = window.location.pathname;

            if(url && currentPath !== '/'){
              isActive = currentPath === url;
            }

            // If not active let's make sure one of the sub items isnt the current page
            if(!isActive){
              isActive = !!items.filter(({link}) => link.url === currentPath).length
            }
          }

          let menu = {
            link: createLink(primary, isActive),
            sub: items.map((link: any) => {
              return { link: createLink(link), sub: [] } // these menus only go one level deep
            })
          }

          prev.push(menu)
          return prev
        }, [])

        // console.log(links)
        // return null;
        return <Navigation
          menuItems={links}
        />
      }}
    />)
}

export const query = graphql`
    query navQuery{
        prismicMainMenu {
            data {
                body {
                    ... on PrismicMainMenuDataBodyMenuItem{
                        items{
                            label
                            link {
                                lang
                                link_type
                                isBroken
                                url
                            }
                        }
                        primary {
                            label
                            style
                            link {
                                lang
                                link_type
                                isBroken
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`
