import * as React from 'react'
import {componentResolverFromMap, PrismicPreviewProvider} from 'gatsby-plugin-prismic-previews'
import page from './src/prismic/templates/page'
import resource from './src/prismic/templates/resource'
import gallery from './src/prismic/templates/gallery'
import showcase from './src/prismic/templates/showcase'
import linkResolver from './src/utils/link-resolver'
import { AuthProvider } from "./src/utils/context"

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          page,
          resource,
          gallery,
          showcase,
        }),
      },
    ]}
  >
    <AuthProvider>
    {element}
    </AuthProvider>
  </PrismicPreviewProvider>
)
