import PrismicPageDataBodyHero from "./PrismicPageDataBodyHero"
import PrismicPageDataBodyText from "./PrismicPageDataBodyText"
import PrismicPageDataBodySpacer from "./PrismicPageDataBodySpacer"
import PrismicPageDataBodyVideo from "./PrismicPageDataBodyVideo"
import PrismicPageDataBodyShowcase from "./PrismicPageDataBodyShowcase"
import PrismicPageDataBodyImageWithText from "./PrismicPageDataBodyImageWithText"
import PrismicPageDataBodyTestimonial from "./PrismicPageDataBodyTestimonial"
import PrismicPageDataBodyButtonOrLink from "./PrismicPageDataBodyButtonOrLink"
import PrismicPageDataBodyGallery from "./PrismicPageDataBodyGallery"
import PrismicPageDataBodyGalleryPageCallout from "./PrismicPageDataBodyGalleryPageCallout"
import PrismicPageDataBodyCallout from "./PrismicPageDataBodyCallout"
import PrismicPageDataBodyResources from "./PrismicPageDataBodyResources"
import PrismicPageDataBodyGrid from "./PrismicPageDataBodyGrid"
import PrismicPageDataBodyForm from "./PrismicPageDataBodyForm"
import PrismicPageDataBodyRawHtml from "./PrismicPageDataBodyRawHtml"
import PrismicPageDataBodyBuildSelector from "./PrismicPageDataBodyBuildSelector"
import PrismicPageDataBodyHubspotForm from "./PrismicPageDataBodyHubspotForm"

import PrismicShowcaseDataBodyGallery from "./PrismicShowcaseDataBodyGallery"
import PrismicShowcaseDataBodyHero from "./PrismicShowcaseDataBodyHero"
import PrismicShowcaseDataBodySpacer from "./PrismicShowcaseDataBodySpacer"
import PrismicShowcaseDataBodyText from "./PrismicShowcaseDataBodyText"
import PrismicShowcaseDataBodyImage from "./PrismicShowcaseDataBodyImage"
import PrismicShowcaseDataBodyImageWithText from "./PrismicShowcaseDataBodyImageWithText"
import PrismicShowcaseDataBodyTestimonial from "./PrismicShowcaseDataBodyTestimonial"
import PrismicShowcaseDataBodyVideo from "./PrismicShowcaseDataBodyVideo"

import PrismicResourceDataBodyButtonOrLink from "./PrismicResourceDataBodyButtonOrLink"
import PrismicResourceDataBodyGallery from "./PrismicResourceDataBodyGallery"
import PrismicResourceDataBodyCallout from "./PrismicResourceDataBodyCallout"
import PrismicResourceDataBodyHero from "./PrismicResourceDataBodyHero"
import PrismicResourceDataBodyImageWithText from "./PrismicResourceDataBodyImageWithText"
import PrismicResourceDataBodyText from "./PrismicResourceDataBodyText"
import PrismicResourceDataBodySpacer from "./PrismicResourceDataBodySpacer"
import PrismicResourceDataBodyVideo from "./PrismicResourceDataBodyVideo"
import PrismicResourceDataBodyImage from "./PrismicResourceDataBodyImage"
import PrismicPageDataBodyImage from "./PrismicPageDataBodyImage"

const slices = {
  PrismicPageDataBodyHero,
  PrismicPageDataBodyText,
  PrismicPageDataBodySpacer,
  PrismicPageDataBodyVideo,
  PrismicPageDataBodyShowcase,
  PrismicPageDataBodyImageWithText,
  PrismicPageDataBodyTestimonial,
  PrismicPageDataBodyButtonOrLink,
  PrismicPageDataBodyGallery,
  PrismicPageDataBodyGalleryPageCallout,
  PrismicPageDataBodyCallout,
  PrismicPageDataBodyResources,
  PrismicPageDataBodyGrid,
  PrismicPageDataBodyForm,
  PrismicPageDataBodyImage,
  PrismicPageDataBodyRawHtml,
  PrismicPageDataBodyBuildSelector,

  PrismicPageDataBodyHubspotForm,

  PrismicShowcaseDataBodyGallery,
  PrismicShowcaseDataBodyHero,
  PrismicShowcaseDataBodySpacer,
  PrismicShowcaseDataBodyText,

  PrismicShowcaseDataBodyImage,
  PrismicShowcaseDataBodyImageWithText,
  PrismicShowcaseDataBodyTestimonial,
  PrismicShowcaseDataBodyVideo,

  PrismicResourceDataBodyButtonOrLink,
  PrismicResourceDataBodyGallery,
  PrismicResourceDataBodyCallout,
  PrismicResourceDataBodyHero,
  PrismicResourceDataBodyImage,
  PrismicResourceDataBodyImageWithText,
  PrismicResourceDataBodyText,
  PrismicResourceDataBodySpacer,
  PrismicResourceDataBodyVideo,
}

export const getSlice = (sliceType) => {
  return slices?.[sliceType]
}
