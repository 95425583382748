import React from "react"
import { Col, Container, Row } from "../Grid/Grid"
import { mb } from "../../utils/spacing"
import styled from "styled-components"
import { media } from "styled-bootstrap-grid"
import { graphql, StaticQuery } from "gatsby"
import Button from "../Button"
import { StaticImage } from "gatsby-plugin-image"
import { StyledImgWrapper } from "./NewsletterSignup"

const StyledHeading = styled.h2`
  ${mb(2)}
  text-align: center;
`

const StyledVoice = styled.span`
  font-family: 'Reenie Beanie', cursive;
  font-size: 30px;
  color: #767575;
`

const StyledFirstImage = styled(Col)`
  margin-top: 32px;
  margin-bottom: 32px;
  text-align: center;

  ${media.sm`
    margin-top: 60px;    
    margin-bottom: 0;
    transform: rotateZ(-15deg) scale(0.9);
  `}

  ${media.md`
    margin-top: 120px;    
  `}
`

const StyledSecondImage = styled(Col)`
  display: none;
  margin-top: 80px;
  text-align: center;

  ${media.md`
    display: block;    
  `}
`
const StyledThirdImage = styled(Col)`
  display: none;
  transform: rotateZ(15deg) scale(0.9);
  margin-top: 60px;
  text-align: center;

  ${media.sm`
    display: block;
  `}

  ${media.md`
    margin-top: 120px;    
  `}
`


const ReadyToBuild = (props) => {
  return (
    <StaticQuery
      query={`${query}`}
      render={({ image1, image2, image3 }) => {

        return <>
          <Container style={{
            backgroundColor: "#F4F4F4",
            paddingTop: "3rem"
          }} fluid>
            <Container>
              <Row>
                <Col
                  sm={12}
                  md={8}
                  mdOffset={2}
                >
                  <StyledHeading>Ready to Build Together?</StyledHeading>
                  <p>
                    Just like on a first date, we want to get to know you, and you to know us, so
                    we
                    can build on this relationship. Don’t worry, we have put together a list of
                    questions
                    to pin-point your style, to understand your needs now, and more importantly,
                    your
                    needs in the future.
                  </p>
                </Col>

                <StyledFirstImage
                  col={12}
                  sm={6}
                  md={4}
                >
                  <StyledVoice>
                    I love this wood pantry door and hardware.
                  </StyledVoice>
                  <StyledImgWrapper>
                    <StaticImage
                      style={{
                        border: "clamp(7px, 0.6944444444vw, 10px) solid #fff"
                      }}
                      src={`../../images/ready-to-build-1.jpg`}
                      alt={"custom RTM home being built"}
                    />
                  </StyledImgWrapper>
                </StyledFirstImage>

                <StyledSecondImage
                  col={4}
                >
                  <StyledImgWrapper>
                    <StaticImage
                      style={{
                        border: "clamp(7px, 0.6944444444vw, 10px) solid #fff"
                      }}
                      src={`../../images/ready-to-build-2.jpg`}
                      alt={"custom RTM home being built"}
                    />
                  </StyledImgWrapper>
                  <StyledVoice>
                    I am in love with this sink!
                  </StyledVoice>
                </StyledSecondImage>

                <StyledThirdImage
                  col={6}
                  md={4}
                >
                  <StyledVoice>
                    A second sink in the island is a MUST
                  </StyledVoice>
                  <StyledImgWrapper>
                    <StaticImage
                      style={{
                        border: "clamp(7px, 0.6944444444vw, 10px) solid #fff"
                      }}
                      src={`../../images/ready-to-build-3.jpg`}
                      alt={"custom RTM home being built"}
                    />
                  </StyledImgWrapper>
                </StyledThirdImage>
              </Row>
            </Container>
          </Container>
          <Container style={{ transform: "translateY(-50%)" }}>
            <Row justifyContent={"center"}>
              <Col auto>
                <Button to={"/design-form/"} primary>Tell Us About Your Style</Button>
              </Col>
            </Row>
          </Container>
        </>
      }}/>
  )
}

export default ReadyToBuild

export const query = graphql`query ReadyToBuildQuery {
    image1: file(relativePath: {eq: "ready-to-build-1.jpg"}) {
        childImageSharp {
            gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
    }
    image2: file(relativePath: {eq: "ready-to-build-2.jpg"}) {
        childImageSharp {
            gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
    }
    image3: file(relativePath: {eq: "ready-to-build-3.jpg"}) {
        childImageSharp {
            gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
    }
}
`
