import React from "react"
import styled from "styled-components"
import ImgWithBorder from "../ImgWithBorder"
import { Col, Container, Row } from "../Grid/Grid"
import uuid from "../../prismic/utils/uuidv4"
import Button from "../Button"

const StyledButton = styled(Button)`
  margin-top: 4rem;
`

const StyledGallery = styled.div`
display: grid;
grid-gap: 2rem;
  
.gatsby-image-wrapper{
  height: 100%;
}

@media(min-width: 576px){
  grid-template-columns: 1fr 1fr;
  
  div:nth-child(3){
    grid-column: 1/-1;
  }
}

@media(min-width: 768px){
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  
  div:nth-child(3){
    grid-row: 1/-1;
    grid-column: 3;
  }
}
`

const Gallery = ({ images, title = "", text = null, buttonText = null, buttonUrl = null }) => {
  const id = uuid()
  return (
    <Container>
      <Row>
        <Col col={12} style={{ textAlign: "center" }}>
          {title &&
            <h2>{title}</h2>
          }
          {text}
          <StyledGallery>
            {images.map((image, i) => <ImgWithBorder key={id + i} {...image}/>)}
          </StyledGallery>
          {buttonText && buttonUrl &&
            <StyledButton primary href={buttonUrl}>{buttonText}</StyledButton>
          }
        </Col>
      </Row>
    </Container>
  )
}

export default Gallery
