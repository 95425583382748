import React from "react"
import styled from "styled-components"
import Img from "../Img"
import Button from "../Button"
import { media } from "styled-bootstrap-grid"

const StyledShowcase = styled.div`
  .showcase__background{
    display: grid;
    grid-template-columns: 8% 34% auto 8%;
    grid-template-rows: 80px 1fr 80px;
    height: 100%;
  }
  .showcase__background--black{
    background-color: #313030;
    grid-column: 1/3;
    grid-row:1/-1;
    height: 100%;
  }
  .showcase__background--grey{
    background-color: #F1F1F1;
    grid-column: 3/-1;
    grid-row:1/-1;
    height: 100%;
  }

  .showcase__wrapper{
    display: grid;
    grid-column: 2/4;
    grid-row:2;
    grid-template-columns: 1.8fr 1fr;
    column-gap: 11%;
  }
  .showcase__left{
    display: grid;
    grid-column: 1;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    grid-row-gap: 16px;
    @media(min-width: 768px){
      grid-row-gap: 32px;
      column-gap: 32px;
    }
    margin-bottom: 2rem;
    ${media.lg`
      margin-bottom: 0;
      grid-template-columns: 1fr 1fr;
    `}
  }
  .showcase__right{
    grid-column: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .showcase__images{
    img{
      border: 6px solid #fff;
      @media(min-width: 768px){
        border: 10px solid #fff;
      }
    }
    filter: drop-shadow(3px 4px 10px rgba(0, 0, 0, 0.25));
    
    &.showcase__images--small{
      img{
        height: auto !important;
        object-fit: contain !important;
      }
    }
    &.showcase__images--large{
      grid-row: 1;
      grid-column: 1/-1;
    }
  }

  .showcase__stats{
    display: flex;
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
  }

  .showcase__stats div{
    flex: 1;
  }

  .showcase__stats > div:nth-child(odd){
    padding-right: 1rem;
    border-right: 1px solid #D1D1D1;
  }
  .showcase__stats > div:nth-child(even){
    padding-left: 1rem;
  }
  .showcase__cta{
    align-self: center;
    margin-top: 4.25rem;
  }

  .showcase__label{
    font-size: 18px;
    color: #8C8C8C;
    margin-top: 20px;
  }

  .showcase__value{
    font-size: 24px;
    font-weight: bold;
    margin-top: 11px;
    color: #484747;
  }

  .img--responsive{
    width: 100%;
    height: auto;
  }

  .showcase__text{
    margin-bottom: 3rem;
  }
  .showcase__text--light{
    font-weight: 800;
    font-size: 18px;
    color: #8C8C8C;
    line-height: 40px;
  }

  @media(max-width: 991px){
    .showcase__background{
      grid-template-rows: 40px 1fr 2fr 40px;
      grid-template-columns: 6% 1fr 6%;
    }
    .showcase__background--black{
      grid-column: 1/-1;
      grid-row: 1/3;
    }
    .showcase__background--grey{
      grid-column: 1/-1;
      grid-row: 3/-1;
    }
    .showcase__wrapper{
      grid-row: 2/4;
      grid-column: 2;
      display: flex;
      flex-direction: column;
    }
    .showcase__images, .showcase__images--large{
    }
  }

  .testimonial__voice{
    font-family: 'Reenie Beanie', cursive;
    font-size: 36px;
    color: #5F5E5E;
    grid-column: testimonial-voice;
    position: relative;
    line-height: 110%;
    p{
      line-height: 110%;
    }
  }
  .testimonial__voice p{
    background-color: #fff;
    transform: rotate(-6deg);
    font-size: 2.25rem;
    margin-top: 30px;
  }
  .testimonial__voice:before{
    content: " ";
    background-image: url('/images/arrow.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform: scaleX(-1) scaleY(1);
    position: absolute;
    top: -50px;
    right: 50px;
    width: 100%;
    height: 100%;
  }
  .testimonial__body{
    font-family: Vollkorn;
    font-style: italic;
    font-size: 24px;
    line-height: 36px;
    color: #484747;
    grid-column: testimonial-body;
    margin-top: 144px;
  }
  .testimonial__name{
    font-family: Vollkorn;
    font-style: italic;
    font-size: 18px;
    line-height: 39px;
    color: #8C8C8C;
  }
 .showcase__testimonial{
    display: grid;
    grid-auto-flow: column;
      grid-template-columns: [testimonial-body] 2fr [testimonial-voice] 1fr;
    column-gap: 80px;
    margin-left: 120px;
    margin-right: 120px;
    z-index: 1;
    
    @media(max-width: 1023px){
      display: flex;
      flex-direction: column;
      
      .testimonial__voice:before{
        display: none;
      }
      .testimonial__voice p{
      margin-top: 3rem;
        transform: rotateZ(0deg);
      }
      .testimonial__body{
      margin-top: 2rem;
      }
    }
    
    @media(max-width: 767px){
      margin-left: 40px;
      margin-right: 40px;
    }
  } 
`

const ShowcaseCallout = ({
                           title,
                           square_feet,
                           excerpt,
                           image_1,
                           image_2,
                           image_3,
                           url,
                           testimonial_body,
                           testimonial_name,
                           testimonial_voice,
                           bedrooms,
                           bathrooms
                         }) => {

  return (
    <StyledShowcase className="showcase">
      <div className="showcase__background">
        <div className="showcase__background--black"></div>
        <div className="showcase__background--grey"></div>

        <div className="showcase__wrapper">
          <div className="showcase__left">
            <Img className={"showcase__images showcase__images--large"} {...image_1} />
            <Img className={"showcase__images"} {...image_2} />
            <Img className={"showcase__images"} {...image_3} />
          </div>
          <div className="showcase__right">
            <div className="showcase__text">
              <h2>{title}</h2>
              <span className="showcase__text--light">{square_feet} sqft</span>
              <p>{excerpt}</p>
            </div>
            <div className="showcase__stats">
              <div>
                <img className="img--responsive" src="/images/bed.png" alt={"bed"}/>
                <div className="showcase__label">Bedrooms</div>
                <div className="showcase__value">{bedrooms}</div>
              </div>
              <div>
                <img className="img--responsive" src="/images/bathtub.png" alt={"bathtub"}/>
                <div className="showcase__label">Bathrooms</div>
                <div className="showcase__value">{bathrooms}</div>
              </div>
            </div>
            <div className={"showcase__cta"}>
              <Button href={url} secondary={true}>Explore this Home</Button>
            </div>
          </div>
        </div>
      </div>
      <div className="showcase__testimonial">
        <div className="testimonial__voice">
          <p>{testimonial_voice}</p>
        </div>
        <div className="testimonial__body">
          <p>{testimonial_body}</p>
          <span className="testimonial__name">{testimonial_name}</span>
        </div>
      </div>
    </StyledShowcase>
  )
}

export default ShowcaseCallout
