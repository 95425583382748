import * as React from "react"
import ImageWithText from "../../components/ImageWithText/ImageWithText"
import GridRenderer from "../../components/GridRenderer/GridRenderer"
import { graphql, StaticQuery } from "gatsby"
import { normalizeImageProps } from "../utils/image"

const PrismicPageDataBodyResources = ({ primary }) => {
  let cols
  switch (primary?.cols) {
    case "1 Column":
      cols = 12
      break
    case "2 Columns":
      cols = 6
      break
    case "3 Columns":
      cols = 4
      break
    case "4 Columns":
      cols = 3
      break
    default:
      cols = 12
  }

  return (
    <StaticQuery
      query={`${resourcesQuery}`}
      render={({ allPrismicResource }) => (
        <GridRenderer
          backgroundColor={""}
          colAtts={{ col: 12, md: cols }}
          colStyles={{ padding: 0, paddingBottom: "4rem" }}
        >
          {allPrismicResource.nodes.map(({ data, uid }, index) => {
              const {
                title,
                excerpt = { html: "" }
              } = data

              return (
                <ImageWithText
                  key={uid + index}
                  heading={`<h2>${title}</h2>`}
                  text={excerpt.html}
                  image={normalizeImageProps(data.featured_image)}
                  buttonUrl={`/resources/${uid}`}
                  buttonText={"Read More"}
                  imagePosition={(index + 1) % 2 === 0 ? "Left" : "Right"}
                />
              )
            }
          )}
        </GridRenderer>
      )}/>

  )
}

const resourcesQuery = graphql`query ResourcesQuery {
    allPrismicResource(sort: {order: DESC, fields: last_publication_date}) {
        nodes {
            data {
                title
                excerpt {
                    html
                }
                featured_image {
                    alt
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
            uid
        }
    }
}
`

export const query = graphql`
    fragment Resources on PrismicPageDataBodyResources {
        slice_type
        slice_label
        __typename
        id
        primary {
            count
        }
    }
`

export default PrismicPageDataBodyResources
