import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyAbBpOpB2_4-9VuFbuOQ6awux_YsNpOu3w",
  authDomain: "sk2-selector.firebaseapp.com",
  projectId: "sk2-selector",
  storageBucket: "sk2-selector.appspot.com",
  messagingSenderId: "620617969702",
  appId: "1:620617969702:web:4e98161ed06851cef8b6e7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// firebaseApps previously initialized using initializeApp()
const db = getFirestore(app);
// connectFirestoreEmulator(db, 'localhost', 8080);

const auth = getAuth(app);

// eslint-disable-next-line no-restricted-globals
if (typeof process.env.NETLIFY === "undefined") {
  // connectFirestoreEmulator(db, 'localhost', 8080);
  // connectAuthEmulator(auth, 'http://localhost:8000');
}

// export default firebase;
export { db, app, auth };
