import * as React from "react"
import { graphql } from "gatsby"
import Gallery from "../../components/Gallery/Gallery"
import parse from "html-react-parser"
import { normalizeImageProps } from "../utils/image"

const PrismicPageDataBodyGalleryPageCallout = ({ primary }) => {
  const { gallery_item: { document: { data }, url } } = primary
  const {
    title = null,
    mini_gallery_1 = null,
    mini_gallery_2 = null,
    mini_gallery_3 = null,
    mini_gallery_4 = null,
    mini_gallery_portrait = null,
    intro_text = null
  } = data

  return (

    <Gallery
      title={title}
      text={parse(intro_text?.html)}
      buttonText={'View Gallery'}
      buttonUrl={url}
      images={[
        normalizeImageProps(mini_gallery_1),
        normalizeImageProps(mini_gallery_2),
        normalizeImageProps(mini_gallery_portrait),
        normalizeImageProps(mini_gallery_3),
        normalizeImageProps(mini_gallery_4)
      ]}
    />
  )
}

export const query = graphql`
    fragment GalleryPageCallout on PrismicPageDataBodyGalleryPageCallout {
        slice_type
        slice_label
        __typename
        primary {
            gallery_item {
                url
                document {
                    ... on PrismicGallery {
                        id
                        data {
                            title
                            intro_text {
                                html
                            }
                            mini_gallery_1 {
                                alt
                                gatsbyImageData(width: 700, placeholder: BLURRED, imgixParams: {ar: "3:2", fit: "crop", crop: "entropy"})
                            }
                            mini_gallery_2 {
                                alt
                                gatsbyImageData(width: 700, placeholder: BLURRED, imgixParams: {ar: "3:2", fit: "crop", crop: "entropy"})
                            }
                            mini_gallery_3 {
                                alt
                                gatsbyImageData(width: 700, placeholder: BLURRED, imgixParams: {ar: "3:2", fit: "crop", crop: "entropy"})
                            }
                            mini_gallery_4 {
                                alt
                                gatsbyImageData(width: 700, placeholder: BLURRED, imgixParams: {ar: "3:2", fit: "crop", crop: "entropy"})
                            }
                            mini_gallery_portrait {
                                alt
                                gatsbyImageData(width: 1100, placeholder: BLURRED, imgixParams: {ar: "1:1.66", fit: "crop", crop: "entropy"})
                            }
                        }
                    }
                }
            }
        }
    }
`

export default PrismicPageDataBodyGalleryPageCallout;
