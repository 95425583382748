import * as React from "react"
import GridRenderer from "../../components/GridRenderer/GridRenderer"
import { graphql } from "gatsby"
import uuidv4 from "../utils/uuidv4"

const PrismicPageDataBodyGrid = ({ primary, items }) => {
  let cols = 4

  switch (primary.columns) {
    case "1 Column":
      cols = 12
      break
    case "2 Columns":
      cols = 6
      break
    case "3 Columns":
      cols = 4
      break
    case "4 Columns":
      cols = 3
      break
    default:
      cols = 4
  }

  return (
    <GridRenderer colAtts={{ col: 12, sm: cols }} colStyles={{
      marginBottom: "50px",
      flexDirection: "column",
      textAlign: "center"
    }}>
      {items.map(({ text }) => {
        return (<div key={uuidv4()} dangerouslySetInnerHTML={{__html: text.html}}/>)
      })}
    </GridRenderer>
  )
}

export const query = graphql`
    fragment Grid on PrismicPageDataBodyGrid {
        slice_type
        slice_label
        __typename
        primary{
            columns
        }
        items {
            text{
                html
            }
        }
    }
`

export default PrismicPageDataBodyGrid;
