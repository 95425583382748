import * as React from "react"
import { graphql } from "gatsby"
import Testimonial from "../../components/Testimonial/Testimonial"
import { normalizeImageProps } from "../utils/image"

const PrismicShowcaseDataBodyTestimonial = ({ primary }) => {
  const { heading = "", image = null, text = "", name = "" } = primary

  return (
    <Testimonial
      heading={heading.html}
      image={normalizeImageProps(image)}
      name={name}
      text={text.html}
    />
  )
}

export const query = graphql`fragment ShowcaseTestimonial on PrismicShowcaseDataBodyTestimonial {
    slice_label
    slice_type
    __typename
    primary {
        heading {
            html
        }
        image {
            alt
            gatsbyImageData(layout: FULL_WIDTH)
        }
        text {
            html
        }
        name
    }
}
`

export default PrismicShowcaseDataBodyTestimonial
