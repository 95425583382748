import * as React from "react"
import { Link as GatsbyLink } from "gatsby"
// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({
                  children,
                  to = null,
                  activeClassName = "",
                  partiallyActive = false,
                  ...other
                }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const href = to || other?.href || "#"

  if(!href){
    return (<span {...other}>{children}</span>)
  }

  const { className = "", style = {} } = other

  const internal = /^\/(?!\/)/.test(href)
  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink
        to={href}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        className={className}
        style={style}
      >
        {children}
      </GatsbyLink>
    )
  }

  return (
    <a href={href} style={style} className={className}>
      {children}
    </a>
  )
}

export default Link;
