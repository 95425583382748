import * as React from 'react'
import Video from "../../components/Video/Video"
import { graphql } from "gatsby"

const PrismicShowcaseDataBodyVideo = ({primary}) => {

  return (
    <Video url={primary?.url} />
  )
}

export const query = graphql`
    fragment ShowcaseVideo on PrismicShowcaseDataBodyVideo {
        slice_label
        slice_type
        __typename
        primary{
            url
        }
    }
`

export default PrismicShowcaseDataBodyVideo;
