import * as React from "react"
import { graphql } from "gatsby"
import ShowcaseCallout from "../../components/ShowcaseCallout/ShowcaseCallout"
import { normalizeImageProps } from "../utils/image"

const PrismicPageDataBodyShowcase = ({ primary }) => {
  const showcase = primary?.showcase
  const data = showcase?.document?.data

  return (
    <ShowcaseCallout
      {...data}
      url={showcase.url}
      image_1={normalizeImageProps(data?.image_1)}
      image_2={normalizeImageProps(data?.image_2)}
      image_3={normalizeImageProps(data?.image_3)}
    />
  )
}

export const query = graphql`fragment Showcase on PrismicPageDataBodyShowcase {
    id
    slice_type
    slice_label
    __typename
    primary {
        showcase {
            url
            document {
                ... on PrismicShowcase {
                    id
                    url
                    data {
                        excerpt
                        square_feet
                        bedrooms
                        bathrooms
                        testimonial_body
                        testimonial_name
                        testimonial_voice
                        title
                        image_1 {
                            alt
                            gatsbyImageData(layout: FULL_WIDTH)
                        }
                        image_2 {
                            alt
                            gatsbyImageData(width: 800, height: 533, layout: CONSTRAINED)
                        }
                        image_3 {
                            alt
                            gatsbyImageData(width: 800, layout: CONSTRAINED)
                        }
                    }
                }
            }
        }
    }
}
`

export default PrismicPageDataBodyShowcase
