import { navigate } from "gatsby-link"
import * as React from "react"
import styled from "styled-components"
import Button from "../Button"
import { Col, Container, Row } from "../Grid/Grid"
import ReCAPTCHA from "react-google-recaptcha"


function encode(data: object) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export const StyledForm = styled.form`
width: 100%;
display: block;
line-height: 150%;
input, select, textarea{
  display: block;
  width: 100%;
  line-height: 100%;
  padding: 15px 20px;
}

label{
  display: block;
  margin-bottom: 20px;
}

input:not([type="submit"]), textarea, select{
  border: 1px solid #8C8C8C;;
}

select{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 15px;
  border-radius: 0;
}

.label{
  margin-bottom: 10px;
  &.required:after{
    content: "*";
    color: #ff4d44;
  }
}

`

interface NetlifyFormProps {
  render: (handleChange: (event: any) => void) => void,
  title: string,
  action: string,
  name: string,
  stores?: string[],
}

declare global {
  interface Window {
    dataLayer: any
  }
}

export default class NetlifyForm extends React.Component<NetlifyFormProps, { isValidated: boolean, formName: string }> {

  private recaptchaRef = React.createRef()

  constructor(props: NetlifyFormProps) {
    super(props)

    this.state = {
      isValidated: false,
      formName: this.props.name
    }
  }

  public handleChange = (event: any) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  public handleSubmit = (e) => {
    e.preventDefault()
    e.persist()
    const recaptchaValue = this.recaptchaRef.current.getValue()
    const form = event.target

    if (window?.dataLayer) {
      window.dataLayer.push({
        "event": "formCompleted",
        "formName": this.state.formName,
        "eventCallback": () => {
          this.submitForm(form, recaptchaValue)
        }
      })
    } else {
      this.submitForm(form, recaptchaValue)
    }
  }

  public submitForm = (form, recaptchaValue) => {
    return fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        ...this.state,
        "form-name": this.state.formName,
        "g-recaptcha-response": recaptchaValue
      })
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  public render() {
    const { title, action, render, stores } = this.props
    const { formName } = this.state

    return (
      <Container>
        <Row>
          <Col col={12} md={6} mdOffset={3}>
            <StyledForm
              action={action}
              name={this.props.name}
              method="POST"
              data-netlify="true"
              netlify-honeypot="bot-field"
              onSubmit={this.handleSubmit}
              data-netlify-recaptcha="true"
            >
              <input type={"hidden"} value={formName} name={"form-name"}/>
              <p style={{ display: "none" }}>
                <label>Don’t fill this out if you're human: <input name="bot-field"/></label>
              </p>
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <h2>{title}</h2>
              </div>
              {render(this.handleChange)}
              <ReCAPTCHA
                ref={this.recaptchaRef}
                sitekey="6LedaO4ZAAAAAPrSgsXrG6UMQqGo1lp0HBdBXgVB"
                style={{ marginBottom: 20 }}
              />
              <div style={{ textAlign: "center" }}>
                <Button as={"input"} value={"Get In Touch"} type={"submit"} primary/>
              </div>
            </StyledForm>
          </Col>
        </Row>
      </Container>
    )
  }
}
