import * as React from "react"
import { media } from "styled-bootstrap-grid"
import { Col, Container, Row } from "../Grid/Grid"
import styled from "styled-components"
import { mb } from "../../utils/spacing"
import Button from "../Button"
import ImgWithBorder from "../ImgWithBorder"

// export interface ImageWithTextProps {
//   image?: null | GatsbyImageFluidProps,
//   imagePosition?: "Top" | "Left" | "Right"
//   text?: React.ReactElement,
//   buttonUrl?: string,
//   buttonText?: string,
//   heading?: React.ReactElement,
//   backgroundColor?: string,
// }

const StyleContainer = styled(Container)`
${props => props["data-image-position"] === "Top" ? mb({ default: 0, md: 2 }) : mb(0)};
`

const StyledImage = styled(Col)`
    width: 100%;
    height: auto;
    ${props => props["data-image-position"] === "Top" ? mb(2) : mb({ default: 2, lg: 0 })};
`

const StyledImageWithText = styled(Container)`
  display: flex;
  flex-direction: column;
  //flex: 1;
  
  button, a{
    margin-top: ${props => props["data-image-position"] === "Top" ? "auto" : "2rem"};
  }
  
  .body-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    ${media.xs`
      margin: 0;    
    `}
  }
  
  h3, h4{
    margin-bottom: 0;
  }
`

const StyledHeading = styled.div`
  pointer-events: none;
  ${mb(1)};
`

const StyledText = styled.div`
  padding-right: 1rem;
  margin-bottom: ${props => props["data-image-position"] === "Top" ? "42px" : "0"};
`
const StyledLink = styled.div`
width: ${props => props["data-image-position"] === "Top" ? "100%" : "auto"};
`

const ImageWithText = (props) => {
  const {
    buttonUrl = "",
    buttonText = "",
    image = null,
    text = null,
    heading = null,
    imagePosition = "Top"
  } = props

  return (
    <StyleContainer
      data-image-position={imagePosition}
      fluid
    >
      <Row>
        <StyledImageWithText data-image-position={imagePosition}>
          <Row style={{ marginLeft: 0, marginRight: 0, flex: 1, alignItems: "center" }}>
            <StyledImage
              data-image-position={imagePosition}
              col={12}
              lg={imagePosition === "Top" ? 12 : 6}
              order={1}
              lgOrder={imagePosition === "Right" ? 2 : 1}
              lgOffset={imagePosition === "Right" ? 1 : 0}
            >
              {image &&
                <ImgWithBorder {...image} />
              }
            </StyledImage>

            <Col
              className={"body-content"}
              col={12}
              lg={imagePosition === "Top" ? 12 : 5}
              order={2}
              lgOrder={imagePosition === "Right" ? 1 : 2}
              lgOffset={imagePosition === "Left" ? 1 : 0}
              style={{ display: "flex", flexDirection: "column" }}
            >
              {heading &&
                <StyledHeading data-image-position={imagePosition} dangerouslySetInnerHTML={{ __html: heading }}/>
              }

              {text &&
                <StyledText data-image-position={imagePosition} dangerouslySetInnerHTML={{ __html: text }}/>
              }

              {buttonUrl &&
                <StyledLink data-image-position={imagePosition}>
                  <Button secondary={true} to={buttonUrl}>{buttonText}</Button>
                </StyledLink>
              }
            </Col>
          </Row>
        </StyledImageWithText>
      </Row>
    </StyleContainer>
  )
}

export default ImageWithText
