exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accounts-[id]-build-selections-js": () => import("./../../../src/pages/accounts/[id]/build-selections.js" /* webpackChunkName: "component---src-pages-accounts-[id]-build-selections-js" */),
  "component---src-pages-admin-build-selector-[id]-pre-selections-tsx": () => import("./../../../src/pages/admin/build-selector/[id]/pre-selections.tsx" /* webpackChunkName: "component---src-pages-admin-build-selector-[id]-pre-selections-tsx" */),
  "component---src-pages-admin-build-selector-create-tsx": () => import("./../../../src/pages/admin/build-selector/create.tsx" /* webpackChunkName: "component---src-pages-admin-build-selector-create-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-my-build-tsx": () => import("./../../../src/pages/my-build.tsx" /* webpackChunkName: "component---src-pages-my-build-tsx" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-prismic-templates-gallery-tsx": () => import("./../../../src/prismic/templates/gallery.tsx" /* webpackChunkName: "component---src-prismic-templates-gallery-tsx" */),
  "component---src-prismic-templates-page-tsx": () => import("./../../../src/prismic/templates/page.tsx" /* webpackChunkName: "component---src-prismic-templates-page-tsx" */),
  "component---src-prismic-templates-resource-tsx": () => import("./../../../src/prismic/templates/resource.tsx" /* webpackChunkName: "component---src-prismic-templates-resource-tsx" */),
  "component---src-prismic-templates-showcase-tsx": () => import("./../../../src/prismic/templates/showcase.tsx" /* webpackChunkName: "component---src-prismic-templates-showcase-tsx" */)
}

