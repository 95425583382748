import * as React from "react"
import { useEffect, useState } from "react"
import { auth } from "./firebase"
import { useAuthState } from "react-firebase-hooks/auth"

export const AuthContext = React.createContext({
  isAdmin: false,
  user: auth?.currentUser,
  token: null
})

export const AuthProvider = ({ children }) => {
  const [user, loading, error] = useAuthState(auth);
  const [token, setToken] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    if (user) {
      user?.getIdTokenResult()?.then(t => setToken(t))
    }
  }, [user])

  useEffect(() => {
    setIsAdmin(!!token?.claims?.admin)
  }, [token])

  return (
    <AuthContext.Provider value={{ user, token, isAdmin }}>{children}</AuthContext.Provider>
  )
}
