import * as React from "react"
import HubspotForm from "../../components/HubspotForm/HubspotForm"
import { graphql } from "gatsby"

const PrismicPageDataBodyHubspotForm = ({primary}) => {
  const { portal_id, form_id } = primary

  return <HubspotForm formId={form_id} portalId={portal_id} />
}

export const query = graphql`
    fragment HubspotForm on PrismicPageDataBodyHubspotForm {
        slice_type
        slice_label
        __typename
        primary {
            form_id
            portal_id
        }
    }
`

export default PrismicPageDataBodyHubspotForm;
