import * as React from "react"
import HubspotForm from "../HubspotForm/HubspotForm"
import {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {baseStyles, primaryStyles} from "../Button"
const StyledNewsletter = styled.div`
  width: 100%;
  
  .fs-webform-container .fserv-container{
    box-shadow: none;
    max-width: none;
  }

  .fs-webform-container .fserv-field+div:not(.fserv-field){
    padding: 0;
  }
  
  &&& button.fserv-button-submit{
    width: 100%;
    ${baseStyles}
    ${primaryStyles}
    
    height: auto;
    font-size: 16px;
    line-height: 1;
  }

  .fs-webform-container .fserv-field{
    padding: 0;
  }
  
  &&& .powered-by{
    display: none!important;
  }
`

export default function NewsletterForm(props) {
    const scriptInsertionPoint = useRef(null);
    const [added, setAdded] = useState(false);

    useEffect(() => {
        if (scriptInsertionPoint.current && !added) {
            // Create script element
            const script = document.createElement('script');
            script.src = 'https://sk2customhomes.myfreshworks.com/crm/sales/web_forms/6d0267699d6724128de5923fcd08fb31c830b405f4ab6e095b3dc624ce670d27/form.js';
            script.id = 'fs_6d0267699d6724128de5923fcd08fb31c830b405f4ab6e095b3dc624ce670d27';
            script.async = true;

            // Insert script at the reference point
            scriptInsertionPoint.current.appendChild(script);
            setAdded(true);

            // Clean up
            return () => {
                if (scriptInsertionPoint.current) {
                    scriptInsertionPoint.current.removeChild(script);
                }
            };
        }
    }, []); // Empty dependency array ensures this runs once after initial render

    return (
        <StyledNewsletter ref={scriptInsertionPoint}></StyledNewsletter>
    );
}
