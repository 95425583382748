import { graphql } from "gatsby"
import * as React from "react"
import { useState } from "react"
import Layout from "../layout"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import Body from "../layout/Body"
import parse from "html-react-parser"
import { Col, Container, Row } from "../../components/Grid/Grid"
import styled from "styled-components"
import { defaultMargins, mb } from "../../utils/spacing"
import RGallery from "react-photo-gallery"
import { GatsbyImage, getSrcSet, getSrc } from "gatsby-plugin-image"
import Carousel, { Modal, ModalGateway } from "react-images"
import { media } from "styled-bootstrap-grid"
import { normalizeImageProps } from "../utils/image"

const StyledMargin = styled.div`
  ${props => mb(props["size"])};
`

const StyledImgWrapper = styled.div`
  filter: drop-shadow(3px 4px 10px rgba(0, 0, 0, 0.25));
`

const StyledImage = styled(GatsbyImage)`
  cursor: zoom-in;

  img {
    border: 7px solid #fff;

    ${media.md`
      border: 10px solid #fff;
    `}
  }
`

const Img = ({ index, onClick, photo, margin }) => (
  <StyledImgWrapper
    style={{ margin, height: photo.height, width: photo.width }}
    onClick={e => onClick(e, { index, photo })}
  >
    <StyledImage
      // fixed={typeof window === "undefined" ? { src: {} } : undefined}
      {...photo.gatsbyImage}
    />
  </StyledImgWrapper>
)

const getImages = imageArray => {
  return [...imageArray]
    .map(({ image }) =>
      ({
        height: image.dimensions.height,
        width: image.dimensions.width,
        gatsbyImage: normalizeImageProps(image),
        src: getSrc(image),
        srcSet: getSrcSet(image)
      }))
}

const styleFn = styleObj => ({ ...styleObj, zIndex: 1040 })

const Gallery = (gallery) => {
  const [isOpen, setOpen] = useState(false)
  const [current, setCurrent] = useState(0)

  const { prismicGallery } = gallery?.data
  const { title = null, intro_text = {}, images = {} } = prismicGallery?.data

  const gastbyImages = getImages(images)

  const imageClick = (e, obj) => {
    setCurrent(obj.index)
    setOpen(true)
  }

  return (
    <Layout>
      <Body doc={prismicGallery}>
        <StyledMargin style={{ width: "100%", display: "block" }} size={defaultMargins}/>
        <Container>
          <Row>
            <Col col={12} style={{ textAlign: "center", margin: "4rem 0" }}>
              <h1>{title}</h1>
              {parse(intro_text?.html)}
            </Col>
            <Col style={{ textAlign: "center", marginBottom: "4rem" }}>
              <RGallery
                photos={gastbyImages}
                onClick={imageClick}
                renderImage={Img}
                targetRowHeight={350}
                margin={16}
              />
              <ModalGateway>
                {isOpen ? (
                  <Modal
                    onClose={() => {
                      setCurrent(0)
                      setOpen(false)
                    }}
                    styles={{ blanket: styleFn, positioner: styleFn }}
                  >
                    <Carousel views={gastbyImages} currentIndex={current}/>
                  </Modal>
                ) : null}
              </ModalGateway>
            </Col>
          </Row>
        </Container>
      </Body>
    </Layout>
  )

}

export const query = graphql`
    query singleGallery($id: String){
        prismicGallery(id: {eq: $id}) {
            __typename
            _previewable
            data {
                meta_title
                meta_description
                title
                intro_text{
                    html
                }
                images{
                    image{
                        alt
                        gatsbyImageData(width: 2000, placeholder: BLURRED)
                        dimensions{
                            width
                            height
                        }
                    }
                }
            }
            uid
            id
            type
            lang
            alternate_languages {
                id
                lang
            }
        }
    }
`

Gallery.query = query

export default withPrismicPreview(Gallery)
