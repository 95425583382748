import * as React from "react"
import styled from "styled-components"
import { Col, Container, Row } from "../Grid/Grid"
import { mb } from "../../utils/spacing"
import Button from "../Button"
import { media } from "styled-bootstrap-grid"

interface FooterProps {
  phone?: string,
  email?: string,
  links?: [],
  ctaHeading?: string,
  ctaText?: string,
  ctaButtonText?: string,
  ctaButtonUrl?: string,
}

const StyledFooter = styled(Container)`
  padding-top: 4rem;
  padding-bottom: 40px; // added so that scroll top button doesn't cover
  background-color: #313030;
  
  a{
    color: #fff;
  }
  
  p{
    font-size: 1.125rem;
    font-weight: 600;
    color: #A3A3A3;
    margin-bottom: 1.5rem;
  }
  
  ul{
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 0.875rem;
    ${mb(2)};
    
    li a{
      //styleName: Button;
      font-size: 18px;
      font-weight: 700;
      line-height: 32px;
      padding-bottom: 4px;
    }
  }
  
  ul.social{
    display: flex;
    li{
      margin-right: 30px;
    }
    
    img{
      filter: invert(0.6);
    }
  }
  
  h3{
    color: #fff;
  }
  
  .logo{
    margin-bottom: 2rem;
  }
  
  .copyright, .copyright a{
    color: #767575;
    text-align: center; 
    font-size: 14px; 
    margin-top: 3rem;
  }
`

const StyledCTA = styled(Col)`
  text-align: center;
  margin-bottom: 4rem;
  ${media.lg`
    text-align: right;
    margin-bottom: 0;
  `}
`

const StyledLinks = styled(Col)`
  text-align: center;
  margin-bottom: 2rem;
  ${media.sm`
    text-align: right;
    margin-bottom: 0;
  `}
  ${media.lg`
    text-align: left;
  `}
`

const StyledLogo = styled(Col)`
  text-align: center;
  .social{
    display: flex;
    justify-content: center;
    li:last-child{
      margin-right: 0;
    }
  }
`

export default function Footer(props: FooterProps) {
  const {
    phone = "",
    email = "",
    links = [],
    ctaHeading = "",
    ctaText = "",
    ctaButtonText = "",
    ctaButtonUrl = ""
  } = props

  return (
    <StyledFooter fluid>
      <Container style={{ margin: "0 auto" }}>
        <Row>
          <StyledLogo order={4} smOrder={2} col={12} sm={6} lg={4}>
            <img className={"logo"} src={"/images/sk2-logo-stacked-light.svg"} alt={"Logo"}/>

            <p>
              {phone && <>P: <a href={"tel:" + phone.replace(/[^0-9]/g, "")}>{phone}</a></>}
              {email && <><br/>E: <a href={"mailto:" + email}>{email}</a></>}
            </p>

            <ul className={"social"}>
              <li><a href={"https://facebook.com/sk2building"}
                     target={"_blank"}><img src={"/images/facebook.svg"} alt={"Facebook"}/></a></li>
              <li><a href={"https://instagram.com/sk2building"}
                     target={"_blank"}><img src={"/images/instagram.svg"} alt={"Instagram"}/></a>
              </li>
              <li><a href={"https://youtube.com/c/sk2group"}
                     target={"_blank"}><img src={"/images/youtube.svg"} alt={"YouTube"}/></a></li>
              <li><a href={"https://twitter.com/sk2building"}
                     target={"_blank"}><img src={"/images/twitter.svg"} alt={"Twitter"}/></a></li>
            </ul>
          </StyledLogo>
          <StyledLinks order={3} smOrder={3} col={12} sm={6} lg={3} lgOffset={1}>
            <ul>
              {links.map(({ link, label }, index) => <li key={"footerLink" + index}>
                <Button to={link.url} isLink={true}>{label}</Button></li>)}
            </ul>
          </StyledLinks>
          <StyledCTA order={1} lgOrder={4} col={12} lg={4}>
            <h3>{ctaHeading}</h3>
            <p>{ctaText}</p>
            <Button to={ctaButtonUrl.url} primary={true}>{ctaButtonText}</Button>
          </StyledCTA>
          <Col order={5} col={12} className={"copyright"}>
            © Copyright 2020 | <Button isLink={true} href={"/privacy"}>Privacy Policy</Button>
          </Col>
        </Row>
      </Container>
    </StyledFooter>
  )
}
