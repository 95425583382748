import * as React from "react"
import { graphql } from "gatsby"
import Gallery from "../../components/Gallery/Gallery"
import { normalizeImageProps } from "../utils/image"

const PrismicResourceDataBodyGallery = ({ items }) => {
  const images = items.map(item => normalizeImageProps(item?.image))

  return (
    <Gallery images={images}/>
  )
}

export const query = graphql`fragment ResourceGallery on PrismicResourceDataBodyGallery {
    slice_type
    slice_label
    __typename
    items {
        image {
            alt
            gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
    }
}
`

export default PrismicResourceDataBodyGallery
