import * as React from "react"
import ImageWithText from "../../components/ImageWithText/ImageWithText"
import GridRenderer from "../../components/GridRenderer/GridRenderer"
import { graphql } from "gatsby"
import { normalizeImageProps } from "../utils/image"

const PrismicResourceDataBodyImageWithText = ({ primary, items, id }) => {
  let cols
  switch (primary?.cols) {
    case "1 Column":
      cols = 12
      break
    case "2 Columns":
      cols = 6
      break
    case "3 Columns":
      cols = 4
      break
    case "4 Columns":
      cols = 3
      break
    default:
      cols = 4
  }

  return (
    <GridRenderer
      backgroundColor={primary.background_color}
      colAtts={{ col: 12, md: cols }}
      colStyles={{ padding: 0 }}
      fluid={!!primary.background_color}
    >
      {items.map((item, index) => {
          const {
            text,
            heading = "",
            button_text = "",
            button_url = null,
            image,
            image_position
          } = item

          return (
            <ImageWithText
              key={id + index}
              heading={heading.html}
              text={text.html}
              image={normalizeImageProps(image)}
              buttonUrl={button_url.url}
              buttonText={button_text}
              imagePosition={image_position}
            />
          )
        }
      )}
    </GridRenderer>
  )
}

export const query = graphql`fragment ResourceImageWithText on PrismicResourceDataBodyImageWithText {
    slice_type
    slice_label
    __typename
    id
    primary {
        cols
        background_color
    }
    items {
        button_text
        button_url {
            url
        }
        text {
            html
        }
        heading {
            html
        }
        image {
            alt
            gatsbyImageData(layout: FULL_WIDTH)
        }
        image_position
    }
}
`

export default PrismicResourceDataBodyImageWithText
