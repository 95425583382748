import * as React from "react"
import { graphql } from "gatsby"
import Gallery from "../../components/Gallery/Gallery"
import { normalizeImageProps } from "../utils/image"

const PrismicShowcaseDataBodyGallery = ({ items }) => {
  const images = items.map(item => normalizeImageProps(item?.image))

  return (
    <Gallery images={images}/>
  )
}

export const query = graphql`
    fragment ShowcaseGallery on PrismicShowcaseDataBodyGallery {
        slice_type
        slice_label
        __typename
        items {
            image {
                alt
                gatsbyImageData(width: 800, placeholder: BLURRED)
            }
        }
    }
`

export default PrismicShowcaseDataBodyGallery;
