import * as React from "react"
import { ReactElement } from "react"
import { ColProps as sbgColProps, ContainerProps, RowProps } from "styled-bootstrap-grid"
import { Col, Container, Row } from "../Grid/Grid"
import styled from "styled-components"
import { py } from "../../utils/spacing"

export interface GridRendererProps extends ContainerProps {
  children: ReactElement | ReactElement[],
  colAtts?: sbgColProps,
  colStyles?: object,
  rowAtts?: RowProps,
  equalHeight?: boolean,
  fluid?: boolean,
  style?: React.StyleHTMLAttributes,
  backgroundColor?: string,
}

const StyledContainer = styled(Container)`
  background-color: ${({ backgroundColor }) => backgroundColor || "transparent"};
  ${({ backgroundColor }) => backgroundColor ? py({ md: 8, default: 5 }) + ";" : ""}
`

export default function GridRenderer(props: GridRendererProps) {
  const {
    children,
    rowAtts = {},
    equalHeight = true,
    colAtts = {},
    fluid = false,
    style = {},
    backgroundColor = null
  } = props

  let { colStyles } = props

  if (equalHeight) {
    colStyles = { ...colStyles, display: equalHeight ? "flex" : "block" }
  }

  const elements = React.Children.map(children, (child, i) => {
    return (<Col {...colAtts} style={colStyles}>{React.cloneElement(child)}</Col>)
  })

  return (
    <StyledContainer {...props} style={style} fluid={fluid}>
      <Row {...rowAtts}>
        {elements}
      </Row>
    </StyledContainer>
  )
}
