import React from "react"
import { graphql } from "gatsby"
import {NewsletterSignup, ReadyToBuild} from '../../components/Callout'

const PrismicResourceDataBodyCallout = ({primary}) => {
    const { type } = primary;
    let component = null;

    switch (type) {
        case 'Newsletter Signup':
            component = <NewsletterSignup />
            break;
        case 'Ready to Build':
            component = <ReadyToBuild />
            break;
        default:
            console.warn(`Could not find a matching component for callout "${type}"` )
    }

    return component
}

export const query = graphql`
    fragment ResourceCallout on PrismicResourceDataBodyCallout {
        slice_type
        slice_label
        __typename
        primary {
            type
        }
    }
`

export default PrismicResourceDataBodyCallout;
