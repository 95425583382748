import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { normalizeImageProps } from "../utils/image"

const PrismicPageDataBodyImage = ({ primary }) => {
  const { image } = primary

  return (
    <GatsbyImage {...normalizeImageProps(image)} />
  )
}

export const query = graphql`fragment Image on PrismicPageDataBodyImage {
    slice_type
    slice_label
    __typename
    primary {
        image {
            alt
            gatsbyImageData(layout: FULL_WIDTH)
        }
    }
}
`

export default PrismicPageDataBodyImage
