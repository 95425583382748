import React from "react"
import { Col, Container, Row } from "../Grid/Grid"
import ImgWithBorder from "../ImgWithBorder"
import styled from "styled-components"

const StyledHeading = styled.div`
  text-align: center;
  margin-bottom: 4.5rem;
`

const StyledImg = styled(ImgWithBorder)`
  margin-bottom: 4.5rem;
`

const StyledText = styled(Col)`
  font-family: Vollkorn, serif;
  font-style: italic;
  color: #484747;

  .name {
    margin-top: 2.5rem;
    color: #8C8C8C;
  }
`

const Testimonial = ({ heading = "", image = null, text = "", name = "" }) => (
  <Container style={{
    paddingTop: "3rem",
    paddingBottom: "3rem"
  }} fluid>
    <Container>
      <Row>
        {heading &&
          <Col col={12} md={8} mdOffset={2} style={{ textAlign: "center" }}>
            <StyledHeading dangerouslySetInnerHTML={{ __html: heading }}/>
          </Col>
        }
        {image &&
          <Col col={12} md={6} mdOffset={3} style={{ textAlign: "center" }}>
            <StyledImg {...image} />
          </Col>
        }
        {text &&
          <StyledText col={12} md={8} mdOffset={2} style={{ textAlign: "center" }}>
            <div dangerouslySetInnerHTML={{ __html: text }}/>
            <div className={"name"}>{name}</div>
          </StyledText>
        }


      </Row>
    </Container>
  </Container>
)

export default Testimonial
