import * as React from "react"
import { graphql, Link } from "gatsby"
import { Container } from "../../components/Grid/Grid"
import { Router } from "@reach/router"

const SomeSubPage = props => {
    return <div>Hi from SubPage with id: {props.code} and action: {props.action}</div>
}

const PrismicPageDataBodyBuildSelector = ({ items }) => {

  return <Container>
  <Link to="/builds/ABC123/">First item</Link> - <Link to="/builds/ABC123/edit/">edit</Link><br />
  <Link to="/builds/DEF123/">Second item</Link> - <Link to="/builds/DEF123/edit/">edit</Link>

  <Router>
    <SomeSubPage path="/builds/" />
    <SomeSubPage path="/builds/:code/" />
    <SomeSubPage path="/builds/:code/:action/" />
  </Router>
  </Container>
}

export const query = graphql`
    fragment BuildSelector on PrismicPageDataBodyBuildSelector {
        slice_type
        slice_label
        __typename
        items {
            phases {
                document {
                    ... on PrismicPhase {
                        __typename
                        id
                        data {
                            name
                            selections {
                                selection {
                                    document {
                                        ... on PrismicSelection {
                                            __typename
                                            id
                                            data {
                                                body {
                                                    ... on PrismicSelectionDataBodySubHeading {
                                                        __typename
                                                        id
                                                        primary {
                                                            sub_description
                                                            title
                                                        }
                                                    }
                                                    ... on PrismicSelectionDataBodyOption {
                                                        __typename
                                                        id
                                                        primary {
                                                            internal_name
                                                            option_description
                                                            price
                                                            price_modifier
                                                            title
                                                        }
                                                        items {
                                                            image {
                                                                gatsbyImageData(width: 200)    
                                                                alt
                                                            }
                                                        }
                                                    }
                                                }
                                                description {
                                                    html
                                                }
                                                name
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export default PrismicPageDataBodyBuildSelector;
