import React from "react"
import { Col, Container, Row } from "../Grid/Grid"
import styled from "styled-components"
import { mb } from "../../utils/spacing"

const StyledContainer = styled(Container)`
text-align: center;
margin-top: 1rem;
`

const StyledSqFt = styled.div`
    font-weight: 800;
    font-size: 35px;
    color: #8C8C8C;
    margin-bottom: 4rem;
`

const StyledStats = styled.div`
  display: flex;
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  ${mb(4)}
  div{
    flex: 1;
  }
  
  & > div:nth-child(odd){
    padding-right: 1rem;
    border-right: 1px solid #D1D1D1;
  }
  & > div:nth-child(even){
    padding-left: 1rem;
  }
  
  .showcase__label{
    font-size: 18px;
    color: #8C8C8C;
    margin-top: 20px;
  }

  .showcase__value{
    font-size: 24px;
    font-weight: bold;
    margin-top: 11px;
    color: #484747;
  }
`

const ShowcaseStats = ({ squareFeet, bedrooms, bathrooms }) => (
  <StyledContainer>
    <Row>
      <Col>
        <StyledSqFt>{squareFeet} sqft</StyledSqFt>
        <StyledStats>
          <div>
            <img className="img--responsive" src="/images/bed.png" alt={"bed"}/>
            <div className="showcase__label">Bedrooms</div>
            <div className="showcase__value">{bedrooms}</div>
          </div>
          <div>
            <img className="img--responsive" src="/images/bathtub.png" alt={"bathtub"}/>
            <div className="showcase__label">Bathrooms</div>
            <div className="showcase__value">{bathrooms}</div>
          </div>
        </StyledStats>
      </Col>
    </Row>
  </StyledContainer>
)

export default ShowcaseStats
