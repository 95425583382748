/**
 * Depending if this is a preview or not, we might get a different shape back
 * from prismic. This function normalizes that so we can always expect a
 * childImageSharp type.
 */
export const normalizeImageProps = (image) => {
  if (!image?.gatsbyImageData) {
    return null
  }

  return {
    // ...image,
    image: image.gatsbyImageData,
    alt: image?.alt ? image.alt : "",
  }
}

