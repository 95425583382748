import * as React from "react"
import styled from "styled-components"
import { Col, Container, Row } from "../Grid/Grid"

interface VideoProps {
  url: string,
}

const StyledWrapper = styled.div`
  position: relative;
  height: 0;
  overflow: hidden;
  max-width: 100%;
`

const videoEmbedUrl = (url: string) => {
  if (!url) return null

  var regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/
  var match = url.match(regExp)

  if (match && match[1].length == 11) {
    return "//www.youtube.com/embed/" + match[1] + "?rel=0&modestbranding=1"
  }

  regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/
  match = url.match(regExp)
  return match && match[5] ? "//player.vimeo.com/video/" + match[5] : null
}

const ratioToPercent = (ratio: string) => {
  const [w, h] = ratio.split(":").map((num) => Number(num))
  return `${(h / w) * 100}%`
}

export default function Video(props: VideoProps) {
  const { url } = props

  const src = videoEmbedUrl(url)
  const paddingBottom = ratioToPercent("16:9")

  if (!src) {
    return null
  }

  return (
    <Container>
      <Row>
        <Col sm={12} md={10} mdOffset={1}>
          <StyledWrapper style={{ paddingBottom }}>
            <iframe
              src={src}
              frameBorder="0"
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%"
              }}
            />
          </StyledWrapper>
        </Col>
      </Row>
    </Container>
  )
}
