import * as React from "react"
import { graphql } from "gatsby"
import Button from "../../components/Button"
import {Container, Row, Col} from 'styled-bootstrap-grid'

const PrismicResourceDataBodyButtonOrLink = ({ primary }) => {
  const { button_text = "", button_url = {}, button_style = "" } = primary

  let primaryStyle, secondaryStyle, isLink

  switch (button_style) {
    case "Button Filled":
      primaryStyle = true
      break
    case "Button Outlined":
      secondaryStyle = true
      break
    case "Text Link":
    default:
      isLink = true
      break
  }

  return (
    <Container>
      <Row>
        <Col col={12} style={{textAlign: 'center'}}>
          <Button to={button_url.url} primary={primaryStyle} secondary={secondaryStyle} isLink={isLink}>{button_text}</Button>
        </Col>
      </Row>
    </Container>
  )
}

export const query = graphql`
    fragment ResourceButtonOrLink on PrismicResourceDataBodyButtonOrLink{
        slice_type
        slice_label
        __typename
        primary{
            button_text
            button_url{
                url
            }
            button_style
        }
    }
`

export default PrismicResourceDataBodyButtonOrLink;
