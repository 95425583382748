import * as React from "react"
import { defaultMargins, defaultSmallMargins, mb } from "../../utils/spacing"
import styled from "styled-components"
import { graphql } from "gatsby"

const StyledMargin = styled.div`
${props => mb(props["size"])};
`

const PrismicResourceDataBodySpacer = (props) => {
  let size
  switch (props.size) {
    case "Small":
      size = defaultSmallMargins
      break
    case "Default":
    default:
      size = defaultMargins
      break
  }

  return (
    <StyledMargin style={{ width: "100%", display: "block" }} size={size}/>
  )
}

export const query = graphql`
    fragment ResourceSpacer on PrismicResourceDataBodySpacer{
        slice_type
        slice_label
        __typename
        primary{
            size
        }
    }
`

export default PrismicResourceDataBodySpacer;
