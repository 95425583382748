import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../layout"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import Body from "../layout/Body"
import { normalizeImageProps } from "../utils/image"
import Hero from '../../components/Hero/Hero'
import ShowcaseStats from '../../components/ShowcaseStats/ShowcaseStats'
import Text from "../../components/Text/Text"

const Showcase = (showcase) => {
  const { prismicShowcase } = showcase?.data
    const {image_1, title, square_feet, subtitle, bedrooms, bathrooms} =  prismicShowcase.data

  return (
    <Layout>
      <Body doc={prismicShowcase}>
        <Hero
          image={normalizeImageProps(image_1)}
          heading={`<h1>${title}</h1>`}
        />
        <Text className={'big-bold'} alignment={'center'}>{subtitle}</Text>
        <ShowcaseStats squareFeet={square_feet} bedrooms={bedrooms} bathrooms={bathrooms} />
      </Body>
    </Layout>
  )

}

export const query = graphql`
    query singleShowcase($id: String){
        prismicShowcase(id: {eq: $id}) {
            __typename
            _previewable
            data {
                meta_title
                meta_description
                title
                subtitle
                square_feet
                bedrooms
                bathrooms
                image_1{
                    alt
                    gatsbyImageData(width: 2000, height: 933 ,placeholder: BLURRED)
                }
                body{
                    ...ShowcaseGallery
                    ...ShowcaseHero
                    ...ShowcaseSpacer
                    ...ShowcaseText
                    ...ShowcaseImage
                    ...ShowcaseImageWithText
                    ...ShowcaseTestimonial
                    ...ShowcaseVideo
                }
            }
            uid
            id
            type
            lang
        }
    }
`

Showcase.query = query

export default withPrismicPreview(Showcase)
