import * as React from "react"
import { graphql } from "gatsby"
import InnerHTML from 'dangerously-set-html-content'

const PrismicPageDataBodyRawHtml = ({primary}) => {

  return (
    <InnerHTML html={primary.html} />
  )
}

export const query = graphql`
    fragment HTML on PrismicPageDataBodyRawHtml {
        slice_label
        slice_type
        __typename
        id
        primary {
            html
        }
    }
`

export default PrismicPageDataBodyRawHtml;
