import * as React from "react"
import styled from "styled-components"
import { Col, Container, Row } from "../Grid/Grid"
import { media } from "styled-bootstrap-grid"

interface TextProps {
  alignment?: "left" | "center" | "right",
  children: React.ReactFragment,
  className?: string,
}

const StyledContainer = styled(Container)`
  &.callout p{
    font-size: 1.5rem;
    line-height: 1.75;
    color: #484747;
  }
  
  &.big-bold{
    //styleName: H2/Desktop;
    font-size: 44px;
    font-style: normal;
    font-weight: 800;
    line-height: 54px;
    text-align: center;
  }
  
  &.customer-voice p{
    font-family: "Reenie Beanie", cursive;
    font-size: 56px;
  }
`

const StyledCol = styled(Col)<TextProps>`
text-align: ${props => props["data-alignment"] ? props["data-alignment"] : "left"};

 h1, h2, h3{
    margin-bottom: 2rem;
    font-weight: bold;
    text-align: center;
    ${media.xs`
      margin-bottom: 1rem;
    `}
}
`

const Text = ({ alignment, children, className }: TextProps) => (
  <StyledContainer className={className}>
    <Row>
      <StyledCol
        sm={12}
        md={8}
        mdOffset={2}
        data-alignment={alignment}
      >
        {children}
      </StyledCol>
    </Row>
  </StyledContainer>
)

export default Text
