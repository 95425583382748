import { theme } from "../theme/theme"

export type SizeType =
  | string
  | number
  | {
  [key: string]: string | number
};

export function mt(sizes: SizeType) {
  return spaceMaker("margin-top", sizes)
}

export function mb(sizes: SizeType) {
  return spaceMaker("margin-bottom", sizes)
}

export function ml(sizes: SizeType) {
  return spaceMaker("margin-left", sizes)
}

export function mr(sizes: SizeType) {
  return spaceMaker("margin-right", sizes)
}

export function my(sizes: SizeType) {
  return spaceMaker("margin-top", sizes) + "\n" + spaceMaker("margin-bottom", sizes)
}

export function mx(sizes: SizeType) {
    return spaceMaker("margin-left", sizes) + "\n" + spaceMaker("margin-right", sizes)
}

export function pt(sizes: SizeType) {
  return spaceMaker("padding-top", sizes)
}

export function pb(sizes: SizeType) {
    return spaceMaker("padding-bottom", sizes)
}

export function pl(sizes: SizeType) {
  return spaceMaker("padding-left", sizes)
}

export function pr(sizes: SizeType) {
  return spaceMaker("padding-right", sizes)
}

export function px(sizes: SizeType) {
    return spaceMaker("padding-left", sizes) + "\n" + spaceMaker("padding-right", sizes)
}

export function py(sizes: SizeType) {
  return spaceMaker("padding-top", sizes) + "\n" + spaceMaker("padding-bottom", sizes)
}

export const defaultMargins = {
  default: 4,
  md: 7,
}

export const defaultSmallMargins = {
  default: 3,
}


function spaceMaker(type, sizes) {
  // TODO merge spacing defaults with theme
  
  if (typeof sizes === "number") {
    return `${type}: ${theme.spacing[sizes]};`
  }

  let css = ""
  if (typeof sizes["default"] !== "undefined" && typeof theme.spacing[sizes["default"]] !== "undefined") {
    css += `${type}: ${theme.spacing[sizes["default"]]};`
  }

  const breakLabels = Object.keys(theme.breakpoints)
  return breakLabels.reduce((prev: any, label) => {
    if (typeof sizes[label] !== "undefined") {
      prev += `@media(min-width: ${theme.breakpoints[label]}px){${type}: ${theme.spacing[sizes[label]]};}`
    }
    return prev
  }, css)
}
