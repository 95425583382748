import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../layout"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import Body from "../layout/Body"

const Page = (page) => {
  return (
    <Layout>
      <Body doc={page?.data?.prismicPage}/>
    </Layout>
  )

}

export const query = graphql`
    query singlePage($id: String){
        prismicPage(id: {eq: $id}) {
            __typename
            _previewable
            data {
                slug_prefix
                meta_title
                meta_description
                title
                body{
                    ... Hero
                    ... Text
                    ... Video
                    ... Showcase
                    ... Image
                    ... ImageWithText
                    ... Testimonial
                    ... ButtonOrLink
                    ... Gallery
                    ... Callout
                    ... Resources
                    ... Grid
                    ... Form
                    ... HTML
                    ... GalleryPageCallout
                    ... HubspotForm
                    ... Spacer
                }
            }
            uid
            id
            type
            lang
            alternate_languages {
                id
                lang
            }
        }
    }
`

Page.query = query

export default withPrismicPreview(Page)
