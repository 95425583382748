import {
  Col as SBGCol,
  ColProps,
  Container as SBGContainer,
  ContainerProps,
  media,
  Row as SBGRow,
  RowProps
} from "styled-bootstrap-grid"
import * as React from "react"
import styled from "styled-components"

const StyledRow = styled(SBGRow)`
  //margin-left: 0;
  //margin-right: 0;

  // ${media.lg`
  //   margin-right: -16px;
  //   margin-left: -16px;
  // `}
`

const StyledContainer = styled(SBGContainer)`
  padding: 16px;
  
  ${SBGContainer}{
    padding-left: 8px !important;
    padding-right: 8px !important;
    //margin-left: -8px;
    //margin-right: -8px;
  }
`

export const Container = (props: ContainerProps) => <StyledContainer {...props} />
export const Col = (props: ColProps) => <SBGCol {...props} />
export const Row = (props: RowProps) => <StyledRow {...props} />
