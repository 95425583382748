import * as React from "react"
import GatsbyBackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import styled from "styled-components"
import { Col, Container, Row } from "../Grid/Grid"
import Button from "../Button"


const StyledHero = styled.div`
  position: relative;
  width: 100%;

  .hero__text {
    z-index: 2;
    position: relative;
    margin-top: -3rem;
    @media (min-width: 768px) {
      margin-top: -120px;
    }
  }

  .hero__inner {
    background-color: #fff;
    text-align: center;
    padding-top: 1rem;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 auto;
  }

  .hero__heading {
    margin-bottom: 2rem;
  }

  .hero__subtitle {
    font-size: 1.5rem;
    line-height: 2.625rem;
    text-align: center;
    color: #484747;
    margin-bottom: 3rem;
  }

  .hero__top-border {
    background-color: #60B345;
    height: 10px;
    width: 100%;
    margin-bottom: 2rem;
  }
`


const Hero = ({
                  image = null,
                  className = "",
                  ratio = 0.54,
                  heading = "",
                  subtitle = "",
                  buttonUrl = "",
                  buttonText = ""
                }) => {


  const bgImage = convertToBgImage(image.image)

  return (
    <StyledHero>
      <GatsbyBackgroundImage
        Tag="div"
        className={className}
        {...bgImage}
        backgroundColor={`#040e18`}
        style={{ paddingBottom: ratio * 100 + "%", minHeight: 400, zIndex: 1 }}
        preserveStackingContext
      />
      <Container className={"hero__text"}>
        <Row>
          <Col
            col={10}
            mdOffset={1}
            className={"hero__inner"}
          >
            <div className={"hero__top-border"}/>
            <div className={"hero__heading"} dangerouslySetInnerHTML={{ __html: heading }}/>
            {subtitle &&
              <div className={"hero__subtitle"}>{subtitle}</div>
            }
            {buttonUrl &&
              <Button primary href={buttonUrl}>{buttonText ?? "Learn More"}</Button>
            }
          </Col>
        </Row>
      </Container>
    </StyledHero>
  )
}

export default Hero
