import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../layout"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import Body from "../layout/Body"

const Resource = (resource) => {
  const { prismicResource } = resource?.data

  return (
    <Layout>
      <Body doc={prismicResource}/>
    </Layout>
  )

}

export const query = graphql`
    query singleResource($id: String){
        prismicResource(id: {eq: $id}) {
            __typename
            _previewable
            data {
                meta_title
                meta_description
                title
                body{
                    ... ResourceHero
                    ... ResourceText
                    ... ResourceVideo
                    ... ResourceImage
                    ... ResourceImageWithText
                    ... ResourceButtonOrLink
                    ... ResourceGallery
                    ... ResourceCallout
                    ... ResourceSpacer
                }
            }
            uid
            id
            type
            lang
            alternate_languages {
                id
                lang
            }
        }
    }
`

Resource.query = query

export default withPrismicPreview(Resource)
