import * as React from "react"

const primaryColor = "#60B345"
// const bodyFontFamily = 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';
const bodyFontFamily = "\"Source Sans Pro\", Roboto, \"Helvetica Neue\", Arial, sans-serif"

export const theme = {
  primaryColor,
  secondaryColor: "#000",
  // buttonTextColor: "#fff",
  bodyFontFamily,
  textColor: "#313030",
  roundness: "0",
  // headingFontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  headingFontFamily: "\"Open Sans\", \"Helvetica Neue\", Helvetica, Arial, sans-serif",
  breakpoints: { // defaults below
    giant: 1200,
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575
    // or you can use aliases
    // giant: 1200,
    // desktop: 992,
    // tablet: 768,
    // phone: 576,
    // smaller: 575,
  },
  spacing: {
    "0": "0 !important",
    "1": "1rem !important",
    "2": "2rem !important",
    "3": "3rem !important",
    "4": "4rem !important",
    "5": "5rem !important",
    "6": "6rem !important",
    "7": "7rem !important",
    "8": "8rem !important",
    "9": "12rem !important",
  },
  row: {
    padding: 16 // default 15
  },
  col: {
    padding: 16 // default 15
  },
  container: {
    padding: 0, // default 15
    maxWidth: { // defaults below
      xl: 1140,
      lg: 960,
      md: 768,
      sm: 576,
      xs: "100%"
      // or you can use aliases
      // giant: 1140,
      // desktop: 960,
      // tablet: 720,
      // phone: 540,
      // smaller: 540,
    }
  }
}

export default theme


