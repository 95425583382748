import styled, { css } from "styled-components"
import Link from "./Link"

export const baseStyles = css`
  display: inline-block;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;

  padding: 1rem 3rem;
  border-radius: 0;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 2px solid ${({ theme }) => theme.primaryColor};

  &:hover {
    text-decoration: none;
  }
`

export const primaryStyles = css`
  background-color: ${({ theme }) => theme.primaryColor};
  color: #fff;

  &:hover {
    background-color: #EFF7EC;
    color: initial;
  }

  &:disabled, &.btn-disabled {
    background-color: #EFF7EC;
    border: 2px solid #AFD9A2;
    color: #AFD9A2;
  }
`

export const secondaryStyles = css`
  background-color: #fff;
  color: ${({ theme }) => theme.textColor};

  &:hover {
    background-color: #EFF7EC;
    color: #000;
  }

  &:disabled, &.btn-disabled {
    border: 2px solid #D2D3D3;
    color: #BCBDBD;
  }
`

const Button = styled(Link)`
  ${({ isLink, theme }) => !isLink && baseStyles}
  ${({ primary, theme }) => !!primary && primaryStyles}
  ${({ secondary, theme }) => !!secondary && secondaryStyles}
  &:disabled {

  }
`

export default Button
