import React from "react"
import { Col, Container, Row } from "../Grid/Grid"
import { mb } from "../../utils/spacing"
import styled from "styled-components"
import { media } from "styled-bootstrap-grid"
// import ImgWithBorder from "../ImgWithBorder"
import { graphql, StaticQuery } from "gatsby"
import NewsletterForm from "../NewsletterForm/NewsletterForm"
import { StaticImage } from "gatsby-plugin-image"


const StyledHeading = styled.h2`
  ${mb(2)}
  text-align: center;
`
const StyledFirstImage = styled(Col)`
  display: none;
  margin-top: 32px;
  margin-bottom: 32px;
  text-align: center;

  ${media.sm`
    display: block;
    margin-top: 60px;    
    margin-bottom: 0;
    transform: rotateZ(-15deg) scale(0.9);
  `}

  ${media.md`
    margin-top: 120px;    
  `}
`

const StyledForm = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 2rem;


  input {
    width: 100%;

    &[type=submit] {
      width: auto;
    }
  }
`
const StyledThirdImage = styled(Col)`
  display: none;
  transform: rotateZ(15deg) scale(0.9);
  margin-top: 60px;
  text-align: center;

  ${media.sm`
    
  `}

  ${media.lg`
    display: block;
    margin-top: 120px;    
  `}
`

const StyledInner = styled(Container)`
  &:before {
    display: none;
    position: absolute;
    content: ' ';
    background-image: url('/images/dotted-path.svg');
    background-size: contain;
    background-position: bottom center;
    background-repeat: no-repeat;
    bottom: 0;
    left: 0;
    right: 0;
    width: 83%;
    margin: 0 auto;
    height: 37%;

    ${media.lg`
      display: block;
    `}
  }
`


export const StyledImgWrapper = styled.div`
  filter: drop-shadow(3px 4px 10px rgba(0, 0, 0, 0.25));
`

const NewsletterSignup = () => {
  return (
    <StaticQuery
      query={`${query}`}
      render={() => {

        return <>
          <Container style={{
            backgroundColor: "#F4F4F4",
            paddingTop: "3rem",
            paddingBottom: "6rem",
            position: "relative"
          }} fluid>
            <StyledInner>
              <Row>
                <Col
                  sm={12}
                  lg={8}
                  lgOffset={2}
                >
                  <StyledHeading>Be Inspired & Learn with Us!</StyledHeading>
                  <p>We get it, you are full of questions and feel like you don’t know where to
                    start. That is about to change. Sign up for our newsletter and we will share
                    insights about every step of the build and inspire you with the amazing homes
                    our friends have built.</p>
                </Col>

                <StyledFirstImage
                  col={12}
                  sm={6}
                  lg={4}
                >
                  <StyledImgWrapper>
                    <StaticImage
                      style={{
                        border: "clamp(7px, 0.6944444444vw, 10px) solid #fff"
                      }}
                      src={`../../images/newsletter-1.jpg`}
                      alt={"custom RTM home being built"}
                    />
                  </StyledImgWrapper>
                </StyledFirstImage>

                <StyledForm
                  col={12}
                  sm={6}
                  lg={4}
                >
                    <NewsletterForm />
                </StyledForm>

                <StyledThirdImage
                  lg={4}
                >
                  <StyledImgWrapper>
                    <StaticImage
                      style={{
                        border: "clamp(7px, 0.6944444444vw, 10px) solid #fff"
                      }}
                      src={`../../images/newsletter-2.jpg`}
                      alt={"custom RTM home being built"}
                    />
                  </StyledImgWrapper>
                </StyledThirdImage>
              </Row>
            </StyledInner>
          </Container>
        </>
      }}/>
  )
}

export default NewsletterSignup

export const query = graphql`query NewsletterSignupImageQuery {
    image1: file(relativePath: {eq: "newsletter-1.jpg"}) {
        childImageSharp {
            gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
    }
    image2: file(relativePath: {eq: "newsletter-2.jpg"}) {
        childImageSharp {
            gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
    }
}
`
