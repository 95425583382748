import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const Img = ({ ...other }) => {
  return (
    <GatsbyImage {...other}/>
  )
}

export default Img
