import * as React from "react"
import ContactForm from "../../components/Form/ContactForm"
import { graphql } from "gatsby"

const PrismicPageDataBodyForm = ({primary}) => {
  const { formType } = primary


  let form = null
  switch (formType) {
    case "Contact":
      form = <ContactForm/>
      break
    default:
      form = null
  }
  return form
}


export const query = graphql`
  fragment Form on PrismicPageDataBodyForm {
      slice_label
      slice_type
      __typename
      primary{
          formType: type
      }
  }
`

export default PrismicPageDataBodyForm;
