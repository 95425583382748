import * as React from "react"
import { useState } from "react"
import ReactHubspotForm from "react-hubspot-form"
import { InView } from "react-intersection-observer"
import styled from "styled-components"
import { Col, Container, Row } from "../Grid/Grid"

const StyledRecaptcha = styled.div`
  display: none;
  font-size: 12px;
  color: #bdbdbd;
  margin-top: 2rem;
  text-align: center;

  a, a:hover {
    color: #bdbdbd;
    text-decoration: underline;
  }
`

const hsCss = `
  ul.multi-container {
    list-style: none;
    padding: 0;
    margin-bottom: 32px;

    label {
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    input {
      margin: 0;
      margin-right: 1rem;
      cursor: pointer;
    }
  }
  
  .hs-input:not([type=file]){
    width: 100%;
    font-size: 16px;
    padding: 10px 15px;
    background-color: #fff!important;
    border: 1px solid #767676;
  }
  
  .hs-button.primary.large{
    font-size: 16px;  
    line-height: 1;
    display: inline-block;
    font-weight: bold;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    -webkit-text-decoration: none;
    text-decoration: none;
    padding: 1rem 3rem;
    border-radius: 0;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    border: 2px solid #60B345;
    background-color: #60B345;
    color: #fff;
    
  }
  
  .hs-button.primary.large:hover{
      font-size: 16px!important;  
    line-height: 1!important;
  }

  .hs-fieldtype-checkbox {
    margin: 1rem 0;
  }

  .hs-button.primary {
  }

  .hs_recaptcha {
    display: none;
  }

  .hs-error-msgs {
    font-size: 1rem;
    font-weight: normal;
    padding: 0;
    list-style: none;
    margin: 0.5rem 0;
    color: #ff4646;
  }

  .hs-form-field {
    margin-bottom: 32px;
  }
  
  .submitted-message {
    padding: 15px 20px;
    background: #aee29d;
    border-radius: 5px;
    color: #000;
}
`

const HubspotForm = ({ portalId, formId }) => {
  const [showForm, setShowForm] = useState(false)
  return (
    <Container>
      <Row>
        <Col>
          <InView rootMargin={"400px"}>
            {({ inView, ref, entry }) => {
              if (inView) {
                setShowForm(true)
              }
              return (
                <div ref={ref}>
                  {showForm &&
                    <React.Fragment>
                      <ReactHubspotForm
                        portalId={portalId}
                        formId={formId}
                        loading={<h3>Loading...</h3>}
                        cssRequired={hsCss}
                      />
                      <StyledRecaptcha>
                        This site is protected by reCAPTCHA and the
                        Google <a href="https://policies.google.com/privacy">Privacy
                        Policy</a> and <a href="https://policies.google.com/terms">Terms of
                        Service</a> apply.
                      </StyledRecaptcha>
                    </React.Fragment>
                  }
                </div>
              )
            }}
          </InView>
        </Col>
      </Row>
    </Container>
  )
}

export default HubspotForm
