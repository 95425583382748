import * as React from "react"
import { graphql, StaticQuery } from "gatsby"
import { ThemeProvider } from "styled-components"
import { defaultRebootTheme } from "styled-reboot"
import { BaseCSS, GridThemeProvider } from "styled-bootstrap-grid"
import Helmet from "react-helmet"
import { GlobalStyles } from "../../theme/global"
import theme from "../../theme/theme"
import { AuthProvider } from "../../utils/context"

const Index = ({ children = null }) => {
  const query = graphql`
      query SiteTitleQuery {
          site {
              id
              siteMetadata {
                  title
                  description
              }
          }
      }
  `
  return (
    <StaticQuery
      query={`${query}`}
      render={data => (
        <ThemeProvider theme={{ ...defaultRebootTheme, ...theme }}>
            <GlobalStyles/>
            <GridThemeProvider gridTheme={theme}>
              <React.Fragment>
                <Helmet titleTemplate={`%s | ${data.site.siteMetadata.title}`}>
                  <meta name="description" content={data.site.siteMetadata.description}/>
                  <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;0,800;1,400&family=Reenie+Beanie&family=Vollkorn&display=swap" rel="stylesheet"/>
                </Helmet>
                <BaseCSS/>
                {children}
                {/*<ScrollTop/>*/}
              </React.Fragment>
            </GridThemeProvider>
        </ThemeProvider>
      )}
    />
  )
}

export default Index
