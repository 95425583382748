import * as React from "react"
import { graphql } from "gatsby"
import HTMLRenderer from "react-html-renderer"
import Link from "../../components/Link"
import Text from "../../components/Text/Text"

const PrismicPageDataBodyText = ({ primary }) => {
  const { text, alignment, style = "" } = primary

  return (
    <Text alignment={alignment} className={style.toLowerCase()}>
      <HTMLRenderer
        html={text.html}
        components={{
          a: Link
        }}
      />
    </Text>
  )
}

export const query = graphql`
    fragment Text on PrismicPageDataBodyText {
        slice_type
        slice_label
        __typename
        primary {
            text{
                html
                raw
            }
            alignment
            style
        }
    }
`

export default PrismicPageDataBodyText;
