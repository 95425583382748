import React from "react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { media } from "styled-bootstrap-grid"

const StyledImgWrapper = styled.div`
  filter: drop-shadow(3px 4px 10px rgba(0, 0, 0, 0.25));
`

const StyledImage = styled(GatsbyImage)`
border: 7px solid #fff;
${media.md`
border: 10px solid #fff;
`}
`

const ImgWithBorder = (props) => {
  return (
    <StyledImgWrapper>
      <StyledImage
        {...props}
        as={props?.static ? StaticImage : GatsbyImage}
        imgStyle={{ ...props?.imgStyle }}
        style={{ ...props?.style }}
      />
    </StyledImgWrapper>
  )
}

export default ImgWithBorder
